import SubTab from 'components/business/SubTab';
import SubTitle from 'components/common/sub/SubTitle';
import React from 'react';

const Business2 = () => {
    const processArr = [
        {
            id: 1,
            title: '부재 철근 탐사',
            icon: '/icons/icon1.png',
        },
        {
            id: 2,
            title: '작업 위치 선정',
            icon: '/icons/icon2.png',
        },
        {
            id: 3,
            title: '보양 후 코어채취',
            icon: '/icons/icon3.png',
        },
        {
            id: 4,
            title: '공시체 수거',
            icon: '/icons/icon4.png',
        },
        {
            id: 5,
            title: '작업 부위 원상복구',
            icon: '/icons/icon5.png',
        },
        {
            id: 6,
            title: '현장 주변 정리',
            icon: '/icons/icon6.png',
        },
        {
            id: 7,
            title: '공시체 두께 확인',
            icon: '/icons/icon9.png',
        },
    ];

    const imageArr = [
        '/images/business21.jpg',
        '/images/business22.jpg',
        '/images/business23.jpg',
        '/images/business24.jpg',
        '/images/business25.jpg',
        '/images/business26.jpg',
    ];
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="업무분야" title="콘크리트 코어채취" src="/images/sub5.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <SubTab
                        left={'콘크리트 압축강도 시험'}
                        right={'기초 및 슬래브 두께 확인'}
                        curr={'right'}
                        leftsrc={'/business/1'}
                        rightsrc={'/business/2'}
                    />
                    <div id="title">
                        <h3>기초 및 슬래브 두께 확인</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            부재의 두께를 확인한 후 연직하중에 대한 구조적 내력을 확보하는지 검토하기 위하여 시행한다.
                        </p>
                        <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
                            {imageArr.map((v, i) => (
                                <div key={i} className=" relative w-full h-full overflow-hidden">
                                    <img
                                        className=" relative w-full h-full object-cover object-center"
                                        src={process.env.PUBLIC_URL + v}
                                        alt="business"
                                    />
                                </div>
                            ))}
                        </div>
                        <h3>평가 기준</h3>
                        <p>
                            기초 및 슬래브 두께 확인 후 연직하중에 대한 구조적 내력을 확보하는지 검토하여 보강이
                            필요할시 탄소 섬유 보강 또는 철판 보강, 이중매트 기초 보강 구조 등 보강대책을 수립하여야
                            한다.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <h3>진행 프로세스</h3>
                    <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-7 lg:grid-rows-3 gap-10 mt-10">
                        {processArr.map((v, i) => (
                            <div key={i} className=" relative w-full h-full bg-white py-10 px-8 rounded-sm">
                                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                                    <p className="font-black">STEP 0{v.id}</p>
                                </div>
                                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                                    <img
                                        className=" relative w-full h-full object-contain"
                                        src={process.env.PUBLIC_URL + v.icon}
                                        alt="icon"
                                    />
                                </div>
                                <h4>{v.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business2;
