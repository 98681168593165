import { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { postBoard, updateBoard } from 'api/board';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export type Board = {
    id: number;
    title: string;
    contents: string;
    clickCount: number;
    created: Date;
    updated: Date;
    adminId: number;
    categoryId: number;
};

export type Category = {
    id: number;
    name: string;
};

const AOneBoard = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);

    const [category, setCategory] = useState<Category[]>(Array);

    const [board, setBoard] = useState<Board>({
        id: 0,
        title: '',
        contents: `<div></div>`,
        clickCount: 0,
        created: new Date(),
        updated: new Date(),
        adminId: 0,
        categoryId: 0,
    });

    useEffect(() => {
        axios
            .get('/api/category')
            .then((res) => setCategory(res.data.data))
            .catch((e) => toast.error(e));
    }, []);

    useEffect(() => {
        console.log(id);
        id !== undefined &&
            axios
                .get(`/api/board/${id}`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        console.log(id);
                        setBoard({
                            id: Number(id),
                            title: res.data.data.title,
                            contents: res.data.data.contents,
                            clickCount: res.data.data.clickCount,
                            created: res.data.data.created,
                            updated: res.data.data.updated,
                            adminId: res.data.data.adminId,
                            categoryId: res.data.data.categoryId,
                        });
                    }
                })
                .catch((e) => console.log(e));
    }, []);

    const customUploadAdapter = (loader: { file: Promise<any> }) => {
        return {
            upload() {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then((file) => {
                        data.append('file', file);

                        id && data.append('id', id);

                        axios
                            .post('/api/image', data)
                            .then((res) => {
                                if (!flag) {
                                    setFlag(true);
                                }
                                resolve({
                                    default: 'https://inoutenc.com' + res.data.data.src,
                                });
                            })
                            .catch((err) => reject(err));
                    });
                });
            },
        };
    };

    function uploadPlugin(editor: {
        plugins: {
            get: (arg0: string) => {
                (): any;
                new (): any;
                createUploadAdapter: (loader: any) => { upload(): Promise<unknown> };
            };
        };
    }) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        };
    }

    return (
        <div className=" relative w-full h-full">
            <div className="flex justify-between items-center">
                <h4>주요실적 등록</h4>
            </div>
            <div className=" relative w-full h-full my-10">
                <input
                    type="text"
                    name="title"
                    id="title"
                    className="!h-12"
                    value={board.title}
                    onChange={(e) => setBoard({ ...board, title: e.target.value })}
                    placeholder="제목을 입력해주세요."
                />
                <div className=" relative w-full h-fit grid grid-cols-3 lg:grid-cols-5 grid-rows-2 lg:grid-rows-1 text-center my-5">
                    {category.length > 0 &&
                        category.map((v, i) => (
                            <div
                                key={i}
                                className={
                                    board.categoryId === v.id
                                        ? 'relative w-full h-full border py-2 border-[var(--point-color)]'
                                        : 'relative w-full h-full border py-2'
                                }
                                onClick={() => setBoard({ ...board, categoryId: v.id })}
                            >
                                <small>{v.name}</small>
                            </div>
                        ))}
                </div>
                <CKEditor
                    editor={ClassicEditor}
                    data={board.contents}
                    config={{
                        extraPlugins: [uploadPlugin],
                    }}
                    onReady={(editor) => {
                        // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        setBoard({ ...board, contents: data });
                    }}
                    onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                    }}
                />
            </div>
            <div className="relative w-full h-fit max-w-96 mx-auto">
                {Number(id) > 0 ? (
                    <button
                        type="button"
                        onClick={() => {
                            setLoading(true);
                            console.log('update', board);
                            updateBoard(board);
                        }}
                        disabled={loading}
                        className=" relative w-full h-fit bg-[var(--point-color)] py-3 text-white rounded-sm"
                    >
                        <p className="font-black">게시글 수정</p>
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            setLoading(true);
                            postBoard(board);
                        }}
                        disabled={loading}
                        className=" relative w-full h-fit bg-[var(--point-color)] py-3 text-white rounded-sm"
                    >
                        <p className="font-black">게시글 등록</p>
                    </button>
                )}
            </div>
        </div>
    );
};

export default AOneBoard;
