import { IconCirclePlus, IconTrashX } from '@tabler/icons-react';
import { boardList, deleteBoard } from 'api/board';
import Pagination from 'components/portfolio/Pagination';
import { CategoryFormat } from 'const/category';
import { dateFormat } from 'const/date';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ABoard = () => {
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [totalPage, settotalPage] = useState(0);

    const [data, setData] = useState<
        {
            id: number;
            title: string;
            contents: string;
            clickCount: number;
            created: Date;
            updated: Date;
            adminId: number;
            categoryId: number;
            images: [];
        }[]
    >(Array);

    useEffect(() => {
        boardList(setData, page, 12, settotalPage);
    }, [page]);

    return (
        <div className=" relative w-full h-full">
            <div className="flex justify-between items-center">
                <h4>주요실적</h4>
                <div onClick={() => navigate('/admin/portfolio/write')} className=" flex items-center gap-1">
                    <small>추가</small>
                    <IconCirclePlus stroke={1.5} size={22} />
                </div>
            </div>
            <div className=" relative w-full h-full my-10 border-t">
                <div className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2">
                    <p>번호</p>
                    <p className="col-span-3">제목</p>
                    <p className="col-span-3">카테고리</p>
                    <p>조회수</p>
                    <p className="col-span-2">작성일</p>
                    <p>삭제</p>
                </div>
                {data.length > 0 &&
                    data.map((v, i) => (
                        <div
                            key={i}
                            className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
                        >
                            <p onClick={() => navigate(`/admin/portfolio/${v.id}`)}>{page * 10 + i + 1}</p>
                            <p onClick={() => navigate(`/admin/portfolio/${v.id}`)} className="col-span-3 ellipsis">
                                {v.title}
                            </p>
                            <p onClick={() => navigate(`/admin/portfolio/${v.id}`)} className="col-span-3 ellipsis">
                                {CategoryFormat(v.categoryId)}
                            </p>
                            <p onClick={() => navigate(`/admin/portfolio/${v.id}`)}>{v.clickCount}</p>
                            <p onClick={() => navigate(`/admin/portfolio/${v.id}`)} className="col-span-2 ellipsis">
                                {dateFormat(v.created)}
                            </p>
                            <p
                                onClick={() => {
                                    if (data.length < 2) {
                                        toast.error('최소 1개 이상의 게시물이 필요합니다.');
                                    } else {
                                        deleteBoard(v.id);
                                    }
                                }}
                                className=" relative w-fit h-fit mx-auto"
                            >
                                <IconTrashX />
                            </p>
                        </div>
                    ))}
            </div>
            <Pagination totalPage={totalPage} curr={page} set={setPage} />
        </div>
    );
};

export default ABoard;
