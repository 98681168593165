import SubTab from 'components/business/SubTab';
import SubTitle from 'components/common/sub/SubTitle';

const Business1 = () => {
    const processArr = [
        {
            id: 1,
            title: '부재 철근 탐사',
            icon: '/icons/icon1.png',
        },
        {
            id: 2,
            title: '작업 위치 선정',
            icon: '/icons/icon2.png',
        },
        {
            id: 3,
            title: '보양 후 코어채취',
            icon: '/icons/icon3.png',
        },
        {
            id: 4,
            title: '공시체 수거',
            icon: '/icons/icon4.png',
        },
        {
            id: 5,
            title: '작업 부위 원상복구',
            icon: '/icons/icon5.png',
        },
        {
            id: 6,
            title: '현장 주변 정리',
            icon: '/icons/icon6.png',
        },
        {
            id: 7,
            title: '공시체 연구소 접수',
            icon: '/icons/icon7.png',
        },
        {
            id: 8,
            title: '시험성적서 송부',
            icon: '/icons/icon8.png',
        },
    ];

    const imageArr = [
        '/images/business11.jpg',
        '/images/business12.jpg',
        '/images/business13.jpg',
        '/images/business14.jpg',
        '/images/business15.jpg',
        '/images/business16.jpg',
    ];
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="업무분야" title="콘크리트 코어채취" src="/images/sub4.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <SubTab
                        left={'콘크리트 압축강도 시험'}
                        right={'기초 및 슬래브 두께 확인'}
                        curr={'left'}
                        leftsrc={'/business/1'}
                        rightsrc={'/business/2'}
                    />
                    <div id="title">
                        <h3>콘크리트 압축강도 시험 KS F 2422</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            비파괴 시험 결과 불합격되면 문제가 된 부분에서 코어를 채취하여 KS F 2422에 따라 코어의
                            압축강도의 시험을 시행한다.
                        </p>
                        <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
                            {imageArr.map((v, i) => (
                                <div key={i} className=" relative w-full h-full overflow-hidden">
                                    <img
                                        className=" relative w-full h-full object-cover object-center"
                                        src={process.env.PUBLIC_URL + v}
                                        alt="business"
                                    />
                                </div>
                            ))}
                        </div>
                        <h3>평가 기준</h3>
                        <p>
                            코어 공시체 지름/높이 <br />
                            기준 : 굵은 골재 최대 치수 25mm × 3배 = Ø75mm / H.150mm
                            <br />
                            최소 : 굵은 골재 최대 치수 25mm × 2배 = Ø50mm / H.100mm
                            <br />
                            코어 강도의 시험 결과는 평균값이 fck의 85%를 초과하고 각각의 값이 75%를 초과하면 적합한
                            것으로 판정한다.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <h3>진행 프로세스</h3>
                    <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-8 lg:grid-rows-3 gap-10 mt-10">
                        {processArr.map((v, i) => (
                            <div key={i} className=" relative w-full h-full bg-white py-10 px-8 rounded-sm">
                                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                                    <p className="font-black">STEP 0{v.id}</p>
                                </div>
                                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                                    <img
                                        className=" relative w-full h-full object-contain"
                                        src={process.env.PUBLIC_URL + v.icon}
                                        alt="icon"
                                    />
                                </div>
                                <h4>{v.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business1;
