import { contactList } from 'api/contact';
import { dateFormat } from 'const/date';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AContact = () => {
    const navigate = useNavigate();

    const [data, setData] = useState<
        {
            id: number;
            name: string;
            phone: string;
            email: string;
            type: string;
            zipcode: string;
            address: string;
            adrDetail: string;
            coreAndMm: string;
            coreType: string;
            workNeed: string;
            contents: string;
            created: Date;
        }[]
    >(Array);

    useEffect(() => {
        contactList(setData);
    }, []);

    console.log(data);
    return (
        <div className=" relative w-full h-full">
            <div className="flex justify-between items-center">
                <h4>온라인문의</h4>
            </div>
            <div className=" relative w-full h-full my-10 border-t">
                <div className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2">
                    <p>번호</p>
                    <p>이름</p>
                    <p className="col-span-2">연락처</p>
                    <p className="col-span-2">용도및구조</p>
                    <p className="col-span-3">내용</p>
                    <p className="col-span-2">작성일</p>
                </div>
                {data.length > 0 &&
                    data.map((v, i) => (
                        <div
                            key={i}
                            onClick={() => navigate(`/admin/inquiry/${v.id}`)}
                            className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
                        >
                            <p>{v.id}</p>
                            <p className="ellipsis">{v.name}</p>
                            <p className="col-span-2 ellipsis">{v.phone}</p>
                            <p className="col-span-2 ellipsis">{v.type}</p>
                            <p className="col-span-3 ellipsis">{v.contents}</p>
                            <p className="col-span-2 ellipsis">{dateFormat(v.created)}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default AContact;
