import SubTab from 'components/business/SubTab';
import SubTitle from 'components/common/sub/SubTitle';

const Business7 = () => {
    const processArr = [
        {
            id: 1,
            title: '작업 위치 선정',
            icon: '/icons/icon2.png',
        },
        {
            id: 2,
            title: '보양 후 코어채취',
            icon: '/icons/icon3.png',
        },
        {
            id: 3,
            title: '콘크리트 천공',
            icon: '/icons/icon5.png',
        },
        {
            id: 4,
            title: '현장 주변 정리',
            icon: '/icons/icon6.png',
        },
    ];

    const imageArr = [
        '/images/business71.jpg',
        '/images/business72.jpg',
        '/images/business73.jpg',
        '/images/business74.jpg',
        '/images/business75.jpg',
        '/images/business76.jpg',
    ];
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="업무분야" title="코어 작업" src="/images/sub10.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <SubTab
                        left={'습식코어'}
                        right={'건식코어'}
                        curr={'right'}
                        leftsrc={'/business/6'}
                        rightsrc={'/business/7'}
                    />
                    <div id="title">
                        <h3>건식코어</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            콘크리트 벽이나 바닥에 설비 배관이나 에어컨 배관 등이 지나갈 수 있도록 벽 또는 바닥에
                            천공하기 위하여 실시한다.
                        </p>
                        <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
                            {imageArr.map((v, i) => (
                                <div key={i} className=" relative w-full h-full overflow-hidden">
                                    <img
                                        className=" relative w-full h-full object-cover object-center"
                                        src={process.env.PUBLIC_URL + v}
                                        alt="business"
                                    />
                                </div>
                            ))}
                        </div>
                        <h3>평가 기준</h3>
                        <p>
                            건식코어는 물을 사용하지 않기에 분진이 발생하나 고성능 집진기 사용으로 최소한의 분진으로
                            깨끗하고 신속하게 코어 작업을 할 수 있는 장점이 있다.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <h3>진행 프로세스</h3>
                    <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-4 lg:grid-rows-2 gap-10 mt-10">
                        {processArr.map((v, i) => (
                            <div key={i} className=" relative w-full h-full bg-white py-10 px-8 rounded-sm">
                                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                                    <p className="font-black">STEP 0{v.id}</p>
                                </div>
                                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                                    <img
                                        className=" relative w-full h-full object-contain"
                                        src={process.env.PUBLIC_URL + v.icon}
                                        alt="icon"
                                    />
                                </div>
                                <h4>{v.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business7;
