export const dateFormat = (beforDate: Date | string | number) => {
    let date = new Date(beforDate);
    let format =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());

    return format;
};
