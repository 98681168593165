import { IconPointerFilled } from '@tabler/icons-react';
import { boardByCategory, boardList } from 'api/board';
import SubTitle from 'components/common/sub/SubTitle';
import Pagination from 'components/portfolio/Pagination';
import { CategoryFormat } from 'const/category';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
    const navigate = useNavigate();

    const [category, setCategory] = useState(0);
    const [page, setPage] = useState(0);
    const [totalPage, settotalPage] = useState(0);

    const [data, setData] = useState<
        {
            id: number;
            title: string;
            contents: string;
            clickCount: number;
            created: Date;
            updated: Date;
            adminId: number;
            categoryId: number;
            images: {
                id: number;
                src: string;
                originName: string;
                savedName: string;
            }[];
        }[]
    >(Array);

    useEffect(() => {
        boardList(setData, page, 12, settotalPage);
    }, []);

    const thumbnail = (contents: string) => {
        let imageArr = [];

        const imageSrcReg = /(<img[^>]*src\s*=\s*[\"']?([^>\"']+)[\"']?[^>]*>)/g;

        while (imageSrcReg.test(contents)) {
            let src = RegExp.$2.trim(); // (5)

            imageArr.push(src);
        }

        return imageArr[0];
    };

    useEffect(() => {
        if (category === 0) {
            boardList(setData, page, 12, settotalPage);
        } else {
            boardByCategory(setData, category, page, settotalPage, 12);
        }
    }, [category, page]);

    return (
        <div className=" relative w-full h-full">
            <SubTitle category="주요실적" title="주요실적" src="/images/sub11.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div className=" relative w-full h-fit grid grid-cols-2 lg:grid-cols-3 text-center cursor-pointer">
                        <div
                            onClick={() => {
                                setPage(0);
                                setCategory(0);
                            }}
                            className={
                                category === 0
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>전체보기</p>
                        </div>
                        <div
                            onClick={() => {
                                setPage(0);
                                setCategory(1);
                            }}
                            className={
                                category === 1
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>콘크리트 코어채취</p>
                        </div>
                        <div
                            onClick={() => {
                                setPage(0);
                                setCategory(2);
                            }}
                            className={
                                category === 2
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>콘크리트 파취 후 철근 채취</p>
                        </div>
                        <div
                            onClick={() => {
                                setPage(0);
                                setCategory(3);
                            }}
                            className={
                                category === 3
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>철근 부식도 조사</p>
                        </div>
                        <div
                            onClick={() => {
                                setPage(0);
                                setCategory(4);
                            }}
                            className={
                                category === 4
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>반발강도 측정 면 제거</p>
                        </div>
                        <div
                            onClick={() => {
                                setPage(0);

                                setCategory(5);
                            }}
                            className={
                                category === 5
                                    ? ' relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3'
                                    : ' relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3'
                            }
                        >
                            <p>코어작업</p>
                        </div>
                    </div>
                    <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-5 my-5 lg:my-10 pb-10">
                        {data.length > 0 &&
                            data.map((v, i) => (
                                <div
                                    data-aos="fade-up"
                                    key={i}
                                    className=" mb-3 cursor-pointer"
                                    onClick={() => navigate(`/portfolio/${v.id}`)}
                                >
                                    <div className=" relative w-full h-44 lg:h-52 xl:h-72 bg-gray-200">
                                        <div className=" absolute left-0 top-0 w-full h-full grid items-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all duration-300 z-10">
                                            <div className=" text-white relative w-fit h-fit mx-auto animate-bounce">
                                                <IconPointerFilled size={30} />
                                            </div>
                                        </div>
                                        <div className=" absolute right-2 bottom-2 w-fit h-fit z-20">
                                            <small className="flex items-center gap-1 font-black text-white">
                                                #&nbsp;{CategoryFormat(v.categoryId)}
                                            </small>
                                        </div>
                                        <img
                                            className=" relative w-full h-full object-cover object-center"
                                            src={process.env.PUBLIC_URL + thumbnail(v.contents)}
                                            alt="thumb"
                                        />
                                    </div>
                                    <div className=" relative w-full h-fit p-4 bg-[var(--light-color)]">
                                        <p className="font-black ellipsis">{v.title}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <Pagination totalPage={totalPage} curr={page} set={setPage} />
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
