import { Link } from 'react-router-dom';

const MFooter = () => {
    return (
        <footer className=" relative w-full h-full px-[calc((100%-var(--container))/2)] bg-[#181818] text-white xl:pb-10">
            <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto py-16">
                <div className=" relative w-fit h-full max-h-8 lg:max-h-11 mx-auto">
                    <img
                        alt="logo"
                        src={process.env.PUBLIC_URL + '/images/white_logo.png'}
                        className=" relative w-auto h-full max-h-8 lg:max-h-11 object-contain"
                    />
                </div>
                <div className=" relative w-full h-fit flex justify-center items-center gap-7 my-10">
                    <Link to={'/about/greetings'} className="opacity-70 hover:opacity-100">
                        <small>회사소개</small>
                    </Link>
                    <small>|</small>
                    <Link to={'/business/1'} className="opacity-70 hover:opacity-100">
                        <small>업무분야</small>
                    </Link>
                    <small>|</small>
                    <Link to={'/portfolio'} className="opacity-70 hover:opacity-100">
                        <small>주요실적</small>
                    </Link>
                    <small>|</small>
                    <Link to={'/contact'} className="opacity-70 hover:opacity-100">
                        <small>온라인문의</small>
                    </Link>
                </div>
                <div className="flex justify-center items-center gap-2 mb-2 lg:mb-0 lg:gap-5 flex-wrap lg:flex-nowrap opacity-90">
                    <small className="font-bold">내외이앤씨</small>
                    <small>|</small>
                    <small>대표자: 원호식</small>
                    <small>|</small>
                    <small>전화: 010-5958-0500</small>
                    <small>|</small>
                    <small>사업자등록번호: 570-06-02697</small>
                </div>
                <div className="flex justify-center items-center gap-2 lg:gap-5 flex-wrap lg:flex-nowrap mb-14 opacity-90">
                    {/* <small>|</small> */}
                    <small>주소: 경기도 화성시 동탄영천로 150 현대실리콘엘리동탄C동 906호</small>
                </div>
                <div className="lg:flex justify-center items-center gap-2 text-center lg:text-left opacity-90">
                    <small>
                        COPYRIGHT ⓒ (주)내외이앤씨. <br className="block lg:hidden" /> ALL RIGHTS RESERVED. DESIGN BY
                        [SMILEEAN]
                    </small>
                    <br className="block lg:hidden" />
                    <Link
                        to={'/admin/login'}
                        className=" relative w-fit h-fit text-xs py-0.5 px-3 mt-2 lg:mt-0 border rounded-md hover:bg-white hover:text-black"
                    >
                        <small>관리자</small>
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default MFooter;
