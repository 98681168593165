import KakaoMap from 'components/about/KakaoMap';
import SubTitle from 'components/common/sub/SubTitle';

const Location = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="회사소개" title="오시는 길" src="/images/sub3.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div id="title">
                        <div className=" relative w-8 h-1 bg-[var(--point-color)] mb-5"></div>
                        <p className="font-black opacity-55">Location</p>
                        <h3>내외이앤씨에 오시는 길을 안내해드립니다.</h3>
                    </div>
                    <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto my-5 lg:my-10">
                        <div id="map" className=" relative w-full h-64 lg:h-[600px] mb-5 z-0">
                            <KakaoMap />
                        </div>
                        <div className="lg:absolute right-5 bottom-5">
                            <div className=" relative w-full h-fit bg-white bg-opacity-80 py-10 px-5 border-t border-b border-gray-200">
                                <h5>CONTACT</h5>
                                <h3>010-5958-0500</h3>
                                <p className="my-3">경기도 화성시 동탄영천로 150 현대실리콘앨리동탄 C동 906호</p>
                                <div className="opacity-80">
                                    <small>Tel.&nbsp;010-5958-0500</small>&nbsp;&nbsp;|&nbsp;{' '}
                                    <small>Fax.&nbsp;031-224-0997</small>&nbsp;&nbsp;|&nbsp;{' '}
                                    <small>Email.&nbsp;inoutenc@naver.com</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
