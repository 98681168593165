import { Outlet, useLocation } from 'react-router-dom';

import MFooter from './footer/MFooter';
import MHeader from './header/MHeader';
import BottomQuick from './quick/BottomQuick';
import RightQuick from './quick/RightQuick';
import AHeader from './header/AHeader';
import AMHeader from './header/AMHeader';

const Layout = () => {
    const { pathname } = useLocation();

    if (pathname.includes('login')) {
        return (
            <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
                <Outlet />
            </div>
        );
    }

    if (pathname.includes('admin')) {
        if (!sessionStorage.getItem('email')) {
            window.location.href = '/admin/login';
            return <></>;
        }
        return (
            <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
                <AMHeader />
                <AHeader />
                <div className="relative w-full h-full py-32 lg:py-36 px-2 lg:pl-72 lg:pr-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] ">
                    <Outlet />
                </div>
            </div>
        );
    }
    return (
        <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
            <MHeader pathname={pathname} />
            <Outlet />
            <RightQuick />
            <BottomQuick />
            <MFooter />
        </div>
    );
};

export default Layout;
