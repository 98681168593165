export const CategoryFormat = (categoryId: number) => {
    let categoryName = '';

    switch (categoryId) {
        case 1:
            categoryName = '콘크리트 코어채취';

            break;
        case 2:
            categoryName = '콘크리트 파취 후 철근 채취';
            break;
        case 3:
            categoryName = '철근 부식도 조사';
            break;
        case 4:
            categoryName = '반발강도 측정 면 제거';
            break;
        default:
            categoryName = '코어작업';
            break;
    }

    return categoryName;
};
