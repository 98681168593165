import axios from 'axios';
import { Board } from 'pages/admin/board/detail';
import { SetStateAction } from 'react';
import { toast } from 'react-toastify';

export const boardList = async (
    setData: React.Dispatch<SetStateAction<any>>,
    page?: number,
    size?: number,
    setTotalPage?: React.Dispatch<SetStateAction<any>>
) => {
    await axios
        .get(`/api/board?page=${page || 0}&size=${size || 10}`)
        .then((res) => {
            if (res.data.result === '0000') {
                setData(res.data.data.content);
                setTotalPage && setTotalPage(res.data.data.totalPages);
            }
        })
        .catch((e) => toast.error(e));
};

export const boardByCategory = async (
    setData: React.Dispatch<SetStateAction<any>>,
    category: number,
    page?: number,
    setTotalPage?: React.Dispatch<SetStateAction<any>>,
    size?: number
) => {
    await axios
        .get(`/api/board/category?page=${page || 0}&size=${size || 10}&category=${category}`)
        .then((res) => {
            if (res.data.result === '0000') {
                setData(res.data.data.content);
                setTotalPage && setTotalPage(res.data.data.totalPages);
            }
        })
        .catch((e) => toast.error(e));
};

export const postBoard = async (board: Board) => {
    let imageArr = [];

    const imageSrcReg = /(<img[^>]*src\s*=\s*[\"']?([^>\"']+)[\"']?[^>]*>)/g;

    while (imageSrcReg.test(board.contents)) {
        let src = RegExp.$2.trim(); // (5)

        console.log(src.slice(src.lastIndexOf('/')).replace('/', ''));

        imageArr.push(src.slice(src.lastIndexOf('/')).replace('/', ''));
    }

    await axios
        .post('/api/board', {
            images: imageArr.toString(),
            title: board.title,
            contents: board.contents,
            adminId: sessionStorage.getItem('id'),
            categoryId: board.categoryId.toString(),
        })
        .then((res) => {
            if (res.data.result === '0000') {
                toast.success('정상적으로 등록되었습니다.', {
                    onClose: () => {
                        window.location.href = '/admin/portfolio';
                    },
                });
            }
        });
};

export const updateBoard = async (board: Board) => {
    let imageArr = [];

    const imageSrcReg = /(<img[^>]*src\s*=\s*[\"']?([^>\"']+)[\"']?[^>]*>)/g;

    while (imageSrcReg.test(board.contents)) {
        let src = RegExp.$2.trim(); // (5)

        console.log(src.slice(src.lastIndexOf('/')).replace('/', ''));

        imageArr.push(src.slice(src.lastIndexOf('/')).replace('/', ''));
    }

    console.log(board);

    await axios
        .put('/api/board', {
            id: board.id.toString(),
            images: imageArr.toString(),
            title: board.title,
            contents: board.contents,
            adminId: sessionStorage.getItem('id'),
            categoryId: board.categoryId.toString(),
        })
        .then((res) => {
            if (res.data.result === '0000') {
                toast.success('정상적으로 수정되었습니다.', {
                    onClose: () => {
                        window.location.href = '/admin/portfolio';
                    },
                });
            }
        });
};

export const deleteBoard = async (id: number) => {
    if (!window.confirm('정말로 삭제하시겠습니까?')) {
        return false;
    }
    await axios.delete(`/api/board/${id}`).then((res) => {
        if (res.data.result === '0000') {
            toast.success('정상적으로 삭제되었습니다.', {
                onClose: () => {
                    window.location.reload();
                },
            });
        }
    });
};

export const boardViewCountPlus = async (id: number) => {
    await axios.put(`/api/board/${id}`).catch((e) => toast.error(e));
};
