const Pagination = ({ totalPage, curr, set }) => {
    const keyboard = Array.from({ length: totalPage }, (v, i) => i + 1);

    return (
        <div className=" relative flex justify-center items-center gap-10">
            <button
                type="button"
                className=" relative w-3 h-3"
                disabled={curr === 0 ? true : false}
                onClick={() => curr > 0 && set(curr - 1)}
            >
                <img alt="icon" src="/icons/arrow.svg" style={{ transform: 'rotate(180deg)' }} />
            </button>
            <div className=" flex gap-5">
                {keyboard &&
                    keyboard.map((v, i) => (
                        <p
                            key={i}
                            onClick={() => set(v - 1)}
                            style={curr !== v - 1 ? { color: 'var(--font-color)' } : { color: 'var(--point-color)' }}
                        >
                            {v}
                        </p>
                    ))}
            </div>
            <button
                type="button"
                disabled={curr + 1 === totalPage ? true : false}
                className=" relative w-3 h-3"
                onClick={() => curr < totalPage && set(curr + 1)}
            >
                <img alt="icon" src="/icons/arrow.svg" />
            </button>
        </div>
    );
};

export default Pagination;
