import axios from 'axios';
import { toast } from 'react-toastify';

export const quickContact = async ({ name, phone, contents }: { name: string; phone: string; contents: string }) => {
    await axios
        .post('/api/contact/quick', {
            name: name,
            phone: phone,
            contents: contents,
            type: '간편문의',
        })
        .then((res) => {
            if (res.data.result === '0000') {
                window.location.href = '/transform';
                // toast.success('문의 등록이 완료되었습니다.', {
                //     onClose: () => {
                //         window.location.reload();
                //     },
                // });
            }
        })
        .catch((e) => toast.error(e));
};

export const contact = async ({
    name,
    phone,
    email,
    type,
    zipcode,
    address,
    addressDetail,
    coreAndMm,
    coreType,
    workNeed,
    contents,
}: {
    name: string;
    phone: string;
    email: string;
    type: string;
    zipcode: string;
    address: string;
    addressDetail: string;
    coreAndMm: string;
    coreType: string;
    workNeed: string;
    contents: string;
}) => {
    await axios
        .post('/api/contact', {
            name: name,
            phone: phone,
            email: email,
            type: type,
            zipcode: zipcode,
            address: address,
            addressDetail: addressDetail,
            coreAndMm: coreAndMm,
            coreType: coreType,
            workNeed: workNeed,
            contents: contents,
        })
        .then((res) => {
            if (res.data.result === '0000') {
                window.location.href = '/transform';
                // toast.success('문의 등록이 완료되었습니다.', {
                //     onClose: () => {
                //         window.location.href = '/';
                //     },
                // });
            }
        })
        .catch((e) => toast.error(e));
};

export const contactList = async (setData: any) => {
    await axios
        .get('/api/contact?page=0&size=10')
        .then((res) => {
            if (res.data.result === '0000') {
                setData(res.data.data.content);
            }
        })
        .catch((e) => toast.error(e));
};
