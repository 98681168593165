import { IconCategory, IconClockHour1, IconEye } from '@tabler/icons-react';
import { boardViewCountPlus } from 'api/board';
import axios from 'axios';
import SubTitle from 'components/common/sub/SubTitle';
import { CategoryFormat } from 'const/category';
import { dateFormat } from 'const/date';
import { Board } from 'pages/admin/board/detail';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PortfolioDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<Board>();

    useEffect(() => {
        params.id &&
            axios.get(`/api/board/${params.id}`).then((res) => {
                if (res.data.result === '0000') {
                    setData(res.data.data);
                    console.log(res.data.data);
                }
            });
    }, [params.id]);

    useEffect(() => {
        boardViewCountPlus(Number(params.id));
    }, [params.id]);
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="주요실적" title="주요실적" src="/images/sub11.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-7 lg:py-14">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    {data && (
                        <div className=" relative w-full h-full my-5 lg:my-10">
                            <h4 className="text-center py-7 lg:py-14">{data.title}</h4>
                            <div className="relative w-full h-full bg-[var(--gray-color)] px-2 py-3 flex justify-between rounded-sm">
                                <small className="flex items-center gap-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    <IconCategory size={20} />
                                    {CategoryFormat(data.categoryId)}
                                </small>
                                <small className="flex items-center gap-1 whitespace-nowrap">
                                    <IconClockHour1 size={20} />
                                    {dateFormat(data.created)}
                                    &nbsp; &nbsp; &nbsp;
                                    <IconEye size={20} />
                                    {data.clickCount}
                                </small>
                            </div>
                            <div className="relative w-full h-full px-2 py-5 lg:py-10">
                                <div
                                    className="contents"
                                    dangerouslySetInnerHTML={{
                                        __html: data.contents,
                                    }}
                                ></div>
                            </div>
                        </div>
                    )}
                    <div className="relative w-full h-fit max-w-96 mx-auto my-14 lg:my-28">
                        <button
                            onClick={() => navigate(-1)}
                            type="button"
                            className=" relative w-full h-fit bg-[var(--gray-color)] py-3 rounded-sm"
                        >
                            <p className="font-black">목록으로</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioDetail;
