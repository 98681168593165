import { login } from 'api/login';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div className=" relative w-full h-full min-h-screen px-[calc((100%-var(--container))/2)] bg-[var(--gray-color)] py-14 lg:py-28">
            <div className=" relative w-full h-full max-w-screen-sm mx-auto bg-white rounded-2xl py-20">
                <div onClick={() => navigate('/')} className=" relative w-fit h-11 mx-auto cursor-pointer">
                    <img
                        className=" relative w-auto h-full object-contain"
                        src={process.env.PUBLIC_URL + '/images/logo.png'}
                        alt="logo"
                    />
                </div>
                <div className=" relative w-full h-fit grid grid-rows-2 gap-5 max-w-[450px] my-12 mx-auto ">
                    <input
                        type="text"
                        name="id"
                        id="id"
                        placeholder="아이디"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="비밀번호"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="relative w-full h-fit max-w-[450px] mx-auto">
                    <button
                        type="button"
                        onClick={() => login({ email, password })}
                        className=" relative w-full h-fit bg-[var(--point-color)] py-3 text-white rounded-sm"
                    >
                        <p className="font-black">관리자 로그인</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
