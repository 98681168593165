import { IconPointerFilled } from '@tabler/icons-react';
import { CategoryFormat } from 'const/category';
import { useNavigate } from 'react-router-dom';

const Portfolio = ({
    data,
}: {
    data: {
        id: number;
        title: string;
        contents: string;
        clickCount: number;
        created: Date;
        updated: Date;
        adminId: number;
        categoryId: number;
        images: {
            id: number;
            src: string;
            originName: string;
            savedName: string;
        }[];
    }[];
}) => {
    const navigate = useNavigate();

    console.log(data);

    const test = (contents: string) => {
        let imageArr = [];

        const imageSrcReg = /(<img[^>]*src\s*=\s*[\"']?([^>\"']+)[\"']?[^>]*>)/g;

        while (imageSrcReg.test(contents)) {
            let src = RegExp.$2.trim(); // (5)

            imageArr.push(src);
        }

        return imageArr[0];
    };

    return (
        <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
            <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                <div className=" relative w-full h-fit mb-10 text-left">
                    <small className=" font-black">Portfolio</small>
                    <h3>콘크리트 코아채취/코어작업/철근부식 전문업체</h3>
                </div>
                <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-5">
                    {data.length > 0 &&
                        data.map((v, i) => (
                            <div
                                data-aos="fade-up"
                                data-aos-delay={i * 100}
                                key={i}
                                className=" mb-3 cursor-pointer"
                                onClick={() => navigate(`/portfolio/${v.id}`)}
                            >
                                <div className=" relative w-full h-44 lg:h-52 xl:h-72 bg-gray-200">
                                    <div className=" absolute left-0 top-0 w-full h-full grid items-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all duration-300 z-10">
                                        <div className=" text-white relative w-fit h-fit mx-auto animate-bounce">
                                            <IconPointerFilled size={30} />
                                        </div>
                                    </div>
                                    <div className=" absolute right-2 bottom-2 w-fit h-fit z-20">
                                        <small className="flex items-center gap-1 font-black text-white">
                                            #&nbsp;{CategoryFormat(v.categoryId)}
                                        </small>
                                    </div>
                                    {/* {v.images.length > 0 && (
                                        <img
                                            className=" relative w-full h-full object-cover object-center"
                                            src={process.env.PUBLIC_URL + v.images[0].src}
                                            alt="thumb"
                                        />
                                    )} */}
                                    {
                                        <img
                                            className=" relative w-full h-full object-cover object-center"
                                            src={process.env.PUBLIC_URL + test(v.contents)}
                                            alt="thumb"
                                        />
                                    }
                                </div>
                                <p>{v.title}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
