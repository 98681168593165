import ContactUs from 'components/home/ContactUs';
import Banner from '../../components/home/Banner';
import Portfolio from 'components/home/Portfolio';
import Service from 'components/home/Service';
import { useEffect, useState } from 'react';
import { boardList } from 'api/board';

const Home = () => {
    const [data, setData] = useState<
        {
            id: number;
            title: string;
            contents: string;
            clickCount: number;
            created: Date;
            updated: Date;
            adminId: number;
            categoryId: number;
            images: {
                id: number;
                src: string;
                originName: string;
                savedName: string;
            }[];
        }[]
    >(Array);

    useEffect(() => {
        boardList(setData, 0, 4);
    }, []);
    return (
        <div>
            <Banner />
            <Service />
            <ContactUs />
            <Portfolio data={data} />
        </div>
    );
};

export default Home;
