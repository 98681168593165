import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RightQuick = () => {
    const navigate = useNavigate();
    const [view, setView] = useState({
        kakao: false,
        number: false,
        contact: false,
    });
    return (
        <nav className=" fixed right-[calc((100%-var(--container))/3)] top-1/2 w-fit h-fit -translate-y-1/2 z-10">
            <div className=" relative w-fit h-fit grid grid-rows-3 gap-2">
                <button
                    type="button"
                    className=" relative w-fit h-fit"
                    onClick={() => window.open('https://open.kakao.com/o/swiKH3Gf')}
                    onMouseEnter={() => {
                        setView({ ...view, kakao: true });
                    }}
                    onMouseLeave={() => {
                        setView({ ...view, kakao: false });
                    }}
                >
                    <div
                        className={
                            view.kakao
                                ? ' absolute right-0 top-2 h-8 bg-white bg-opacity-85 rounded-l-3xl overflow-hidden w-48 transition-all duration-700 text-left px-3 grid items-center border'
                                : ' absolute right-0 top-2 h-8 rounded-l-3xl overflow-hidden w-0 px-3 grid items-center'
                        }
                    >
                        <small>카카오톡&nbsp;상담&nbsp;바로가기&nbsp;&gt;</small>
                    </div>
                    <div className=" relative w-12 h-12 rounded-lg overflow-hidden">
                        <img src={process.env.PUBLIC_URL + '/images/icon_kakao.jpg'} alt="kakao" />
                    </div>
                </button>
                <button
                    type="button"
                    onClick={() => (window.location.href = 'tel:010-5958-0500')}
                    className=" relative w-fit h-fit"
                    onMouseEnter={() => {
                        setView({ ...view, number: true });
                    }}
                    onMouseLeave={() => {
                        setView({ ...view, number: false });
                    }}
                >
                    <div
                        className={
                            view.number
                                ? ' absolute right-0 top-2 h-8 bg-white bg-opacity-85 rounded-l-3xl overflow-hidden w-44 transition-all duration-700 text-left px-3 grid items-center border'
                                : ' absolute right-0 top-2 h-8 rounded-l-3xl overflow-hidden w-0 px-3 grid items-center'
                        }
                    >
                        <small>전화&nbsp;상담&nbsp;바로가기&nbsp;&gt;</small>
                    </div>
                    <div className=" relative w-12 h-12 rounded-lg overflow-hidden">
                        <img src={process.env.PUBLIC_URL + '/images/icon_call.jpg'} alt="call" />
                    </div>
                </button>
                <button
                    type="button"
                    onClick={() => navigate('/contact')}
                    className=" relative w-fit h-fit"
                    onMouseEnter={() => {
                        setView({ ...view, contact: true });
                    }}
                    onMouseLeave={() => {
                        setView({ ...view, contact: false });
                    }}
                >
                    <div
                        className={
                            view.contact
                                ? ' absolute right-0 top-2 h-8 bg-white bg-opacity-85 rounded-l-3xl overflow-hidden w-48 transition-all duration-700 text-left px-3 grid items-center border'
                                : ' absolute right-0 top-2 h-8 rounded-l-3xl overflow-hidden w-0 px-3 grid items-center'
                        }
                    >
                        <small>온라인&nbsp;상담&nbsp;바로가기&nbsp;&gt;</small>
                    </div>
                    <div className=" relative w-12 h-12 rounded-lg overflow-hidden">
                        <img src={process.env.PUBLIC_URL + '/images/icon_contact.jpg'} alt="contact" />
                    </div>
                </button>
            </div>
        </nav>
    );
};

export default RightQuick;
