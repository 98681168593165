import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'ckeditor5/ckeditor5.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Home from './pages/home';
import Layout from './components/common/Layout';
import Greetings from './pages/about/greetings';
import Equipment from './pages/about/equipment';
import Location from './pages/about/location';

import Business1 from 'pages/business/1';
import Business2 from 'pages/business/2';
import Business3 from 'pages/business/3';
import Business4 from 'pages/business/4';
import Business5 from 'pages/business/5';
import Portfolio from 'pages/portfolio';
import Contact from 'pages/contact';
import Business6 from 'pages/business/6';
import Business7 from 'pages/business/7';
import AdminLogin from 'pages/login';
import Dashboard from 'pages/admin';
import ABoard from 'pages/admin/board';
import AOneBoard from 'pages/admin/board/detail';
import PortfolioDetail from 'pages/portfolio/Detail';
import AContact from 'pages/admin/contact';
import AOneContact from 'pages/admin/contact/detail';
import AOneUpdateBoard from 'pages/admin/board/detail/update';
import Transform from 'pages/transform';

function App() {
    function vh_height() {
        var vh = window.outerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        vh_height();
        AOS.init({
            duration: 800,
        });
    }, []);
    return (
        <div className="App">
            <ToastContainer autoClose={3000} />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />

                    <Route path="about">
                        <Route path="greetings" element={<Greetings />} />
                        <Route path="equipments" element={<Equipment />} />
                        <Route path="location" element={<Location />} />
                    </Route>

                    <Route path="business">
                        <Route path="1" element={<Business1 />} />
                        <Route path="2" element={<Business2 />} />
                        <Route path="3" element={<Business3 />} />
                        <Route path="4" element={<Business4 />} />
                        <Route path="5" element={<Business5 />} />
                        <Route path="6" element={<Business6 />} />
                        <Route path="7" element={<Business7 />} />
                    </Route>

                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/portfolio/:id" element={<PortfolioDetail />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/transform" element={<Transform />} />

                    <Route path="admin">
                        <Route path="login" element={<AdminLogin />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="portfolio" element={<ABoard />} />
                        <Route path="portfolio/write" element={<AOneBoard />} />
                        <Route path="portfolio/:id" element={<AOneUpdateBoard />} />
                        <Route path="inquiry" element={<AContact />} />
                        <Route path="inquiry/:id" element={<AOneContact />} />
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
