import { quickContact } from 'api/contact';
import { useState } from 'react';

const BottomQuick = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [contents, setContents] = useState('');

    return (
        <div className="hidden xl:inline-block fixed left-0 bottom-0 w-full h-fit bg-blue-900 text-white py-3 px-[calc((100%-var(--container))/2)] z-30">
            <div className=" relative w-full h-fit grid grid-cols-4 items-center gap-5">
                <button className=" relative full h-full flex items-center gap-2">
                    <div className=" relative w-14 h-14">
                        <img
                            className=" relative w-full h-full object-contain"
                            src={process.env.PUBLIC_URL + '/images/icon_call2.png'}
                            alt="icon"
                        />
                    </div>
                    <div className=" relative w-fit pt-2 text-left">
                        <h4 className="leading-4 gmarket">010-5958-0500</h4>
                        <small className=" text-xs">&nbsp;▶︎&nbsp;운영시간 : 9시 ~ 19시</small>
                    </div>
                </button>
                <div className=" col-span-3 relative w-full h-fit grid grid-cols-4 gap-2 text-[var(--font-color)]">
                    <div className=" relative w-full h-full">
                        <input
                            type="text"
                            id="name1"
                            className="relative w-full !h-10 rounded-md text-xs !pl-12 pr-2"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="성함을 입력하세요."
                        />
                        <label htmlFor="name1" className="absolute left-2.5 top-2.5 text-sm">
                            성함
                        </label>
                    </div>
                    <div className=" relative w-full h-full">
                        <input
                            type="text"
                            id="phone1"
                            className="relative w-full !h-10 rounded-md text-xs !pl-[77px] pr-2"
                            value={phone}
                            maxLength={11}
                            onChange={(e) => setPhone(e.target.value.replaceAll(/[^0-9.]/g, ''))}
                            placeholder="전화번호를 입력하세요."
                        />
                        <label htmlFor="phone1" className="absolute left-2.5 top-2.5 text-sm">
                            전화번호
                        </label>
                    </div>
                    <div className=" relative w-full h-full">
                        <input
                            type="text"
                            id="contents1"
                            className="relative w-full !h-10 rounded-md text-xs !pl-12 pr-2"
                            value={contents}
                            onChange={(e) => setContents(e.target.value)}
                            placeholder="내용을 입력하세요."
                        />
                        <label htmlFor="contents1" className="absolute left-2.5 top-2.5 text-sm">
                            내용
                        </label>
                    </div>
                    <div className=" relative w-full h-full grid grid-cols-3 items-center gap-2 text-white">
                        <button
                            type="button"
                            className=" col-span-2 relative w-full h-full bg-[var(--point-color)] rounded-md"
                            onClick={() => quickContact({ name, phone, contents })}
                        >
                            <p>간편 견적 접수</p>
                        </button>
                        <label className="relative w-full h-fit text-xs text-left flex" htmlFor="privacy2">
                            <input
                                type="checkbox"
                                name="privacy2"
                                id="privacy2"
                                className=" relative w-fit h-fit mt-0.5"
                                defaultChecked={true}
                            />
                            &nbsp;개인정보처리방침동의
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomQuick;
