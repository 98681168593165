const Service = () => {
    return (
        <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
            <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                <div className=" relative w-full h-fit mb-10 text-left">
                    <small className=" font-black">Our Service</small>
                    <h3>
                        내외이앤씨는 안전진단 및 내진성능평가에 필요한 콘크리트 코어 시료채취 및 철근 파취, 철근 부식도
                        확인, 염화물 시험 등 최상의 장비를 갖추고 수많은 프로젝트를 전문적인 기술로 선도해온 기업입니다.
                    </h3>
                </div>
                <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-3 lg:grid-rows-1 gap-5">
                    <div
                        data-aos="fade-left"
                        className=" relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-black text-white overflow-hidden"
                    >
                        <img
                            className=" relative w-full h-full object-cover object-center opacity-60 lg:hover:scale-110 transition-transform duration-500"
                            src={process.env.PUBLIC_URL + '/images/business16.jpg'}
                            alt="business"
                        />
                        <p className=" absolute left-3 top-3 lg:left-5 lg:top-5">콘크리트 코어채취</p>
                    </div>
                    <div
                        data-aos="fade-up"
                        className=" relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-black text-white overflow-hidden"
                    >
                        <img
                            className=" relative w-full h-full object-cover object-center opacity-60 lg:hover:scale-110 transition-transform duration-500"
                            src={process.env.PUBLIC_URL + '/images/home2.jpg'}
                            alt="business"
                        />
                        <p className=" absolute left-3 top-3 lg:left-5 lg:top-5">콘크리트 파취 후 철근채취</p>
                    </div>
                    <div
                        data-aos="fade-right"
                        className=" relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-black text-white overflow-hidden"
                    >
                        <img
                            className=" relative w-full h-full object-cover object-center opacity-60 lg:hover:scale-110 transition-transform duration-500"
                            src={process.env.PUBLIC_URL + '/images/home3.jpg'}
                            alt="business"
                        />
                        <p className=" absolute left-3 top-3 lg:left-5 lg:top-5">철근 부식 진단</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
