import SubTitle from 'components/common/sub/SubTitle';

const Business5 = () => {
    const processArr = [
        {
            id: 1,
            title: '작업 위치 선정',
            icon: '/icons/icon10.png',
        },
        {
            id: 2,
            title: '도장, 요철, 이물질 등을 제거',
            icon: '/icons/icon11.png',
        },
        {
            id: 3,
            title: '측정 점의 표시',
            icon: '/icons/icon9.png',
        },
        {
            id: 4,
            title: '시험',
            icon: '/icons/icon8.png',
        },
        {
            id: 5,
            title: '작업 부위 원상복구',
            icon: '/icons/icon5.png',
        },
        {
            id: 6,
            title: '현장 주변 정리',
            icon: '/icons/icon6.png',
        },
    ];

    const imageArr = [
        '/images/business51.jpg',
        '/images/business52.jpg',
        '/images/business53.jpg',
        '/images/business54.jpg',
        '/images/business55.jpg',
        '/images/business56.jpg',
    ];
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="업무분야" title="반발 경도 측정 면 제거" src="/images/sub8.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div id="title">
                        <h3>반발 경도 측정 면 제거 KS F 2730</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            경화 콘크리트 면에 슈미트해머로 타격에너지를 가하여 콘크리트 면의 경도에 따라 반발 경도를
                            측정하고, 이 반발 경도와 콘크리트 압축강도와의 상관관계를 도출함으로써 콘크리트의 압축강도를
                            추정한다.
                        </p>
                        <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
                            {imageArr.map((v, i) => (
                                <div key={i} className=" relative w-full h-full overflow-hidden">
                                    <img
                                        className=" relative w-full h-full object-cover object-center"
                                        src={process.env.PUBLIC_URL + v}
                                        alt="business"
                                    />
                                </div>
                            ))}
                        </div>
                        <h3>평가 기준</h3>
                        <p>
                            강도 추정은 측정된 자료의 분석 및 보정을 통하여 평균 반발 경도를 산정하고, 현장에 적합한
                            강도 추정 식을 산정하여 평가하도록 한다.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <h3>진행 프로세스</h3>
                    <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-6 lg:grid-rows-2 gap-10 mt-10">
                        {processArr.map((v, i) => (
                            <div key={i} className=" relative w-full h-full bg-white py-10 px-8 rounded-sm">
                                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                                    <p className="font-black">STEP 0{v.id}</p>
                                </div>
                                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                                    <img
                                        className=" relative w-full h-full object-contain"
                                        src={process.env.PUBLIC_URL + v.icon}
                                        alt="icon"
                                    />
                                </div>
                                <h4>{v.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business5;
