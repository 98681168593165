import axios from 'axios';
import { toast } from 'react-toastify';

export const login = async ({ email, password }: { email: string; password: string }) => {
    await axios
        .post('/api/admin/login', {
            email: email,
            password: password,
        })
        .then((res) => {
            if (res.data.result === '0000') {
                console.log(res.data);
                sessionStorage.setItem('id', res.data.id);
                sessionStorage.setItem('email', res.data.email);
                sessionStorage.setItem('name', res.data.name);

                toast.success(`${res.data.name}님 환영합니다.`, {
                    autoClose: 1000,
                    onClose: () => {
                        sessionStorage.getItem('email') && window.open('/admin/dashboard');
                    },
                });
            } else {
                toast.error(res.data.message);
            }
        })
        .catch((e) => toast.error(e));
};

export const logout = async () => {
    // await axios
    //     .post('/api/admin/logout')
    //     .then((res) => {
    //         sessionStorage.clear();
    //         toast.success(`메인 홈페이지로 이동합니다.`, {
    //             autoClose: 1000,
    //             onClose: () => {
    //                 window.location.href = '/';
    //             },
    //         });
    //     })
    //     .catch((e) => toast.error(e));
    sessionStorage.clear();
    toast.success(`메인 홈페이지로 이동합니다.`, {
        autoClose: 1000,
        onClose: () => {
            window.location.href = '/';
        },
    });
};
