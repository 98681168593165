import { useNavigate } from 'react-router-dom';

const SubTab = (props: { left: string; right: string; curr: string; leftsrc: string; rightsrc: string }) => {
    const navigate = useNavigate();

    return (
        <div className=" relative w-full h-full max-w-screen-sm mx-auto border grid grid-cols-2 mb-7 lg:mb-14 text-center cursor-pointer">
            <div
                onClick={() => navigate(props.leftsrc)}
                className={
                    props.curr === 'left'
                        ? 'relative w-full h-full bg-[var(--font-color)] text-white p-5 '
                        : 'relative w-full h-full text-[var(--font-color)] p-5'
                }
            >
                <h5>{props.left}</h5>
            </div>
            <div
                onClick={() => navigate(props.rightsrc)}
                className={
                    props.curr === 'right'
                        ? 'relative w-full h-full bg-[var(--font-color)] text-white p-5 '
                        : 'relative w-full h-full text-[var(--font-color)] p-5'
                }
            >
                <h5>{props.right}</h5>
            </div>
        </div>
    );
};

export default SubTab;
