import React from 'react';

const SubTitle = (props: { category: string; title: string; src: string }) => {
    return (
        <div className=" relative w-full h-96 lg:h-[600px]">
            <div className=" relative w-full h-full bg-black">
                <img
                    className=" relative w-full h-full object-cover opacity-50"
                    src={process.env.PUBLIC_URL + props.src}
                    alt="home"
                />
            </div>
            <div className="absolute left-0 top-0 w-full h-full text-white">
                <div className="relative w-full h-full px-[calc((100%-var(--container))/2)]">
                    <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto grid items-center">
                        <div className=" relative w-full h-fit pt-10">
                            <span className=" flex items-center gap-3">
                                <button type="button" className=" relative w-4 h-4">
                                    <img
                                        className=" relative w-full h-full object-contain"
                                        src={process.env.PUBLIC_URL + '/icons/icon_home.svg'}
                                        alt="home"
                                    />
                                </button>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + '/icons/arrow_right.svg'}
                                        alt=">"
                                        width={5}
                                        height={5}
                                    />
                                </span>
                                <small>{props.category}</small>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + '/icons/arrow_right.svg'}
                                        alt=">"
                                        width={5}
                                        height={5}
                                    />
                                </span>
                                <small>{props.title}</small>
                            </span>
                            <h1 className="font-black">{props.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubTitle;
