import { useEffect, useState } from 'react';

const Transform = () => {
    const [start, setStart] = useState(false);

    function preLoad(url: string) {
        const image = new Image();
        image.src = url;
        image.onload = () => {
            setStart(true);
        };
    }

    useEffect(() => {
        preLoad('/icons/complete.svg');
    }, []);

    const [sec, setSec] = useState(10);

    useEffect(() => {
        let interval = setTimeout(() => {
            start && sec > 0 && setSec(sec - 1);
        }, 1000);

        if (sec === 0) window.location.href = '/';

        return () => clearTimeout(interval);
    }, [sec, start]);
    return (
        <div className=" relative w-full h-full">
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] my-5 py-14 lg:py-28">
                <div className=" relative w-full h-full mx-auto max-w-screen-sm">
                    <img className=" relative w-full h-auto object-contain" src="/icons/complete.svg" alt="complete" />
                </div>
                <div className=" relative w-full h-full my-5 pb-10 text-center">
                    <h3>문의 접수가 완료되어 {sec}초 뒤 메인페이지로 이동합니다.</h3>
                </div>
                <div className=" relative w-fit h-fit mx-auto ">
                    <button
                        type="button"
                        onClick={() => (window.location.href = '/')}
                        className=" relative w-fit h-fit py-1 px-10 lg:py-3 lg:px-32 bg-[var(--point-color)] text-white rounded-full hover:shadow-lg"
                    >
                        <h4>메인페이지로 돌아가기</h4>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Transform;
