import React from 'react';
import { useNavigate } from 'react-router-dom';

const MNav = (props: { onNav: boolean; setOnNav: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const navigate = useNavigate();

    return (
        <nav
            className={
                props.onNav
                    ? 'fixed lg:hidden right-0 top-0 bg-white text-[var(--font-color)] w-4/5 h-[100vh] overflow-y-auto transition-all duration-500 z-50'
                    : 'fixed lg:hidden right-[-100%] top-0 bg-white text-[var(--font-color)] w-4/5 h-[100vh] overflow-y-auto transition-all duration-700 z-50'
            }
        >
            <button type="button" className=" absolute right-8 top-8 w-4 h-4" onClick={() => props.setOnNav(false)}>
                <img
                    className=" relative w-full h-full object-contain"
                    src={process.env.PUBLIC_URL + '/icons/icon_x.svg'}
                    alt="X"
                />
            </button>
            <ul className="grid grid-cols-1 pt-16">
                <li className="text-[17px] font-semibold py-2 px-6 cursor-pointer">
                    회사소개
                    <ul>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/about/greetings');
                            }}
                        >
                            인사말
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/about/equipments');
                            }}
                        >
                            장비보유현황
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/about/location');
                            }}
                        >
                            오시는 길
                        </li>
                    </ul>
                </li>
                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li className="text-[17px] font-semibold py-2 px-6 cursor-pointer">
                    업무분야
                    <ul>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/business/1');
                            }}
                        >
                            콘크리트 코어채취
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/business/3');
                            }}
                        >
                            콘크리트 파취 후 철근 채취
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/business/4');
                            }}
                        >
                            철근 부식도 조사
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/business/5');
                            }}
                        >
                            반발경도 측정 면 제거
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/business/6');
                            }}
                        >
                            코어작업
                        </li>
                    </ul>
                </li>
                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                        navigate('/portfolio');
                    }}
                >
                    주요실적
                </li>
                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                        navigate('/contact');
                    }}
                >
                    온라인문의
                </li>
            </ul>
        </nav>
    );
};

export default MNav;
